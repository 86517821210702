// Author : Fahad Kamran;
// Date : 05-MAY-2023;
// packages use
// Bootstrap v5.2.3
// react bootstrap v2.7.4
// @reduxjs/toolkit v1.9.5
// redux v4.2.1
// react-redux v8.0.5
// react-router-dom v6.11.1
// react-icon-kit v2.0.0

import React from 'react';

// ==============BOOTSTRAP==============
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

// ==============TOAST==============
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// ==============GLOBAL-SCSS==============
import './App.css';
import AppRouter from './Config/AppRouter';

function App() {

  const lang = localStorage.getItem('FodiEats') || "en"; // get langauge from the localstorage

  document.dir = lang === 'ur' || lang === 'ar' ? 'rtl' : 'ltr';

  return (
    <main className={lang === 'ur' || lang === 'ar' ? 'rtl' : 'ltr'}>
      <ToastContainer />
      <AppRouter />
    </main>
  )
}

export default App;