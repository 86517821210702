import { createSlice } from "@reduxjs/toolkit";


export const fodiEats = createSlice({
    name: 'FODIEATS',
    initialState: {
        language: [],
        location: {},
        location_info: "",
        location_social_media: [],
        homeLoader: false,

        menuLoader: false,
        items: [],
        menus: [],
        promotionBanner: false,
    },
    reducers: {
        setLocationData: ((state, { payload }) => {
            let { language, location, location_info, location_social_media } = payload;
            state.language = language;
            state.location = location;
            state.location_info = location_info;
            state.location_social_media = location_social_media;
        }),
        toogleHomeLoader: ((state, { payload }) => {
            state.homeLoader = payload;
        }),
        setMenusData: ((state, { payload }) => {
            let { item, menus, location } = payload;
            state.items = item;
            state.menus = menus;
            state.promotionBanner = location?.promotion_banner;
        }),
        toogleMenuLoader: ((state, { payload }) => {
            state.menuLoader = payload;
        }),
    },
});

export const { setLocationData, toogleHomeLoader, setMenusData, toogleMenuLoader } = fodiEats.actions;
export default fodiEats.reducer;