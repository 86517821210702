import React from 'react';
import './detailcontent.css';

function DetailContentMain({ itemdetail = {}, ingredients = [], specials = [], price = [] }) {
    return (
        <div className="prodcutDetailContent p-4">
            <h5 className="title">
                <span className='productName me-2'>{itemdetail?.name || ""}</span>
                {/* {specials?.map((item, index) => (
                    <img key={index} src={item?.image} title={item?.name || ""} alt={item?.name || ""} className="icons" />
                ))} */}
            </h5>
            <p><em>{itemdetail?.description || ""}</em></p>
            <div className="mb-2 ingredient__name__container">
                {ingredients?.map((item, index) => (
                    // <img key={index} src={item?.image} title={item?.name || ""} alt={item?.name || ""} className="icons" />
                    <h6 key={index}>{item?.name || ""}</h6>
                ))}
            </div>
            {price?.map((item, index) => (
                <div key={index} className="d-flex justify-content-between mb-2">
                    <span>{`${item?.name || ""} portion (${item?.calories || ""} Cal)`}</span>
                    <span className="textPrimary">{itemdetail?.currency || ""} {item?.price || ""}</span>
                </div>
            ))}
        </div>
    );
};

export default DetailContentMain;