// create file 21-Mar-2024
let menusUrl = 'https://fodieats.com/backend/uploads/menus/';
let locationUrl = 'https://fodieats.com/backend/uploads/locations/';
let promotionUrl = 'https://fodieats.com/backend/uploads/promotions/';
let itemsUrl = 'https://fodieats.com/backend/uploads/items/';

export const domainName = "https://fodieats.com/";

export const baseUrl = "https://fodieats.com/administrator/index.php"; // 04-july
export const baseImageUrl = "https://fodieats.com/administrator/uploads/locations/"; // locations based images
export const menusImageBaseUrl = "https://fodieats.com/administrator/uploads/menus/";

export const textTruncate = (str="", limit=10)=>{
    if(str?.length > limit){
        return `${str.slice(0, limit)}..`
    } else {
        return str;
    }
};

// utils.js
export const getCompanyAndLocation = (pathname) => {
    const parts = pathname.split('/').filter(Boolean);

    if (parts.length >= 2) {
        let company = parts[0];
        let location = parts[1];
        return { company, location };
    }

    return { company: null, location: null };
};


export const setLocationImages = (img) => {
    if (img) {
        const filename = img.split('/').pop();
        return `${locationUrl}${filename}`;
    }
    else {
        return '';
    }
}

export const setMenusImages = (img) => {
    if (img) {
        const filename = img.split('/').pop();
        return `${menusImageBaseUrl}${filename}`;
    }
    else {
        return '';
    }
}

export const setBannerImages = (img) => {
    if (img) {
        const filename = img.split('/').pop();
        return `${promotionUrl}${filename}`;
    }
    else {
        return '';
    }
}

export const setItemsImages = (img) => {
    if (img) {
        const filename = img.split('/').pop();
        return `${itemsUrl}${filename}`;
    }
    else {
        return '';
    }
}