import React, { Fragment } from 'react';
import { domainName } from '../../Config/Helps/helper';
import Logo from "../../assets/images/_logo.png"

function NotFound() {
    return (
        <Fragment>
            <section className="notFound h-100" id="notFound">
                <div className="h-100 d-flex flex-column align-items-center justify-content-between py-3">
                    <img src={Logo} style={{ maxWidth: 130, height: "auto" }} alt="FodiEats" />
                    <div className='text-center px-3'>
                        <h1 className="textPrimary mb-3">404 Error!</h1>
                        <p className="fs_18 mb-3">The page content you are looing for cannot be found!</p>
                    </div>
                    <a href={domainName} className="textPrimary fs_18 mb-0 footerText fodieatsFooter">Powered by FodiEats</a>
                </div>
            </section>
        </Fragment>
    )
}

export default NotFound;