// get All Menu reducer
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../AXIOS";

// Create an async thunk all menu
export const getItemDetail = createAsyncThunk(
    'ITEM/DETAIL',
    async (params, thunkAPI) => {
        let id = params.productId;
        let lang = params.lang;
        const response = await API.post('/Api/getItemDetail', { id, language_id: lang });
        return response.data;
    }
);

export const itemDetailSlice = createSlice({
    name: 'ITEMDETAIL',
    initialState: {
        loader: false,
        error: null,
        data: []
    },
    reducers: {
        // other reducer actions
    },
    extraReducers: (builder) => {
        builder
            .addCase(getItemDetail.pending, (state) => {
                state.loader = true;
            })
            .addCase(getItemDetail.fulfilled, (state, action) => {
                state.loader = false;
                state.data = action.payload;
            })
            .addCase(getItemDetail.rejected, (state, action) => {
                state.loader = false;
                state.error = action.error.message;
            })
    }
});

export default itemDetailSlice.reducer;