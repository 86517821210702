import React, { Fragment, useEffect, useState } from 'react';
import BackNavigation from '../../Components/BackNavigation';
import MenuDrawer from '../../Components/MenuDrawer';
import MenuTabCarousel from '../../Components/MenuTabCarousel';
import Loader from '../../Components/Loader';
import InfoModal from '../../Components/InfoModal';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import LogoSkeleton from '../../Components/LogoSkeleton';
import { baseImageUrl, domainName, getCompanyAndLocation } from '../../Config/Helps/helper';
import httpRequest from '../../Config/AXIOS/httpRequest';
import { setMenusData, toogleMenuLoader } from '../../Config/Store/Reducer/fodiEats';
import { toast } from 'react-toastify';
function Menu() {
    const _location = useLocation(); // Retrieve the current location object from React Router
    const dispatch = useDispatch();

    const [imageError, setImgError] = useState(false);

    const { location = {}, menuLoader = false, menus = [], items = [], promotionBanner, location_info } = useSelector(store => store.fodiEats);

    // Function to fetch location data from the API
    const fecthMenus = async ({ location, company }) => {
        let data = new FormData(); // Create a new FormData object
        data.append('company', company); // Append company to the FormData object
        data.append('location', location); // Append location to the FormData object
        data.append('language_id', "en"); // Append language_id to the FormData object

        dispatch(toogleMenuLoader(true));
        const { res, err } = await httpRequest({ method: "post", url: "/Api/getMenus", data }); // Make a POST request to fetch menus data
        if (res) {
            dispatch(setMenusData(res)); // Dispatch the response data to the Redux store
        } else {
            toast(err || "Error", { type: "error" }); // Display an error message using toast
        }
        dispatch(toogleMenuLoader(false));
    };

    // useEffect hook to fetch location data when the component mounts or the location changes
    useEffect(() => {
        const { company, location } = getCompanyAndLocation(_location?.pathname); // Extract company and location from the pathname
        if (!company || !location) {
            window.location.href = domainName; // Redirect to the domain name if company or location is missing
        } else {
            fecthMenus({ location, company }); // Fetch location data
        }
    }, []);

    return (
        <Fragment>
            <section className="menu" id="menu">
                {/* info modal */}
                <InfoModal
                    data={location_info}
                />
                {menuLoader ? <Loader />
                    :
                    <div className="py-3 text-center">
                        <div className="d-flex justify-content-between align-items-center mb-3 px-3">
                            <span>
                                {location?.skip_home === '0' &&
                                    <BackNavigation extra='flexUnset' />
                                }
                            </span>
                            <div>
                                {
                                    imageError
                                        ?
                                        <LogoSkeleton w={60} h={60} />
                                        :
                                        <img
                                            src={`${baseImageUrl}/${location?.image}`}
                                            onError={() => setImgError(true)}
                                            alt="logo" className="logoSmall"
                                        />
                                }
                            </div>
                            <MenuDrawer />
                        </div>
                        <div className="px-3">
                            <h4 className="textPrimary">Main Menu</h4>
                            <p className="fs_18 mb-2">{location?.tag1 || ""}</p>
                            <p className="textLight">{location?.tag2 || ""}</p>
                        </div>
                        <MenuTabCarousel
                            items={items}
                            menus={menus}
                            promotionBanner={promotionBanner}
                        />
                    </div>
                }
            </section>
        </Fragment>
    );
};

export default Menu;