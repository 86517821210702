import { configureStore } from "@reduxjs/toolkit";

import LocationReducer from './Reducer/locationSlice'; // old
import MenuReducer from './Reducer/menuSlice'; // old
import ItemDetailReducer from './Reducer/itemDetailSlice'; // old

import fodiEats from "./Reducer/fodiEats";

export default configureStore({
    reducer: {
        location: LocationReducer,
        menus: MenuReducer,
        detail: ItemDetailReducer,

        fodiEats
    },
});