import React, { Fragment, useState } from 'react';
import './menutab.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Link } from 'react-router-dom';
import { domainName, textTruncate } from '../../Config/Helps/helper';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
function MenuTabCarousel({ items = [], menus = [], promotionBanner = false }) {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const ItemDescription = ({ text }) => {
        const [isMore, setIsMore] = useState(false);

        return (
            <p className="mb-1 fs_15 text-dark">
                {isMore ? text : text?.slice(0, 90)}

                {text?.length > 90 && <button className="more__less__btn" onClick={() => setIsMore(!isMore)}>{isMore ? "...see less" : "...see more"}</button>}
            </p>
        )
    };

    return (
        <Fragment>
            <Box className='position-relative'>
                {menus?.length > 0 ?
                    // if menu is present length is greater then 0
                    <div className='stickyContent'>
                        <button className='leftArrow' disabled={value === 0} onClick={() => setValue(value - 1)}><KeyboardArrowLeftIcon className='rotate180' /></button>
                        <button className='rightArrow' disabled={value === menus?.length - 1} onClick={() => setValue(value + 1)}><KeyboardArrowRightIcon className='rotate180' /></button>
                        <Box className='px-3 pb-3 mb-3' sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                {menus?.map((item, index) => (
                                    <Tab key={index} className='menuTabs pb-3 px-0' icon={<img src={item?.image} className='img-fluid rounded' />} label={textTruncate(item?.name, 14) || ""} {...a11yProps(index)} />
                                ))}
                            </Tabs>
                        </Box>
                    </div>
                    :
                    <h1 className='textPrimary py-3'>Menu is Empty</h1> // if menu not present
                }
                <div className="px-3">
                    {promotionBanner ?
                        <img src={promotionBanner} alt="banner" className='img-fluid rounded' />
                        :
                        // <LogoSkeleton w={'100%'} h={200} />
                        ""
                    }
                </div>

                {menus.map((v, i) => {
                    // Filter the data in menus.name
                    const filteredItems = items.filter(item => item?.cate_name === v?.name);
                    // Check if there are any matching items
                    const hasItems = filteredItems.length > 0;
                    return value === i && (
                        <div key={i} className='py-0 px-3'>
                            {
                                hasItems ? <>
                                    {/* if item matched */}
                                    {filteredItems.map((cateItem, index) => (
                                        <div key={index}>
                                            {index === 0 && <h5 className="my-3 textPrimary fw-bold">{v?.name}</h5>}
                                            <div className="card card-body shadow overflow-hidden productCard mb-3">
                                                <div className="row m-0">
                                                    <div className="col-9 ps-0 pe-2">
                                                        <div className="productDetail">
                                                            <Link key={index} to={`./${cateItem?.item_id}/view`} className="productTitle text-decoration-underline">{cateItem?.name || ""}</Link>
                                                            <ItemDescription text={cateItem?.description || ""} />
                                                            <p className="product_price textPrimary fs_14 mb-2">{`${cateItem?.currency || ""} ${cateItem?.price || ""}`}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-3 p-0 mb-2">
                                                        <Link key={index} to={`./${cateItem?.item_id}/view`}>
                                                            <img src={cateItem?.image} alt="starter" className="img-fluid rounded" />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                                    :
                                    <>
                                        {/* if no item matched */}
                                        <h5 className="my-3 textPrimary fw-bold">{v?.name}</h5>
                                        <div className="card card-body shadow mb-3 py-5">
                                            <h6 className='fs_20 mb-0'>{`${v?.name} Items Not Found.`}</h6>
                                        </div>
                                    </>
                            }
                        </div>
                    );
                })}
            </Box>
            <a href={domainName} target='_blank' rel="noreferrer" className="textPrimary fs_16 mb-0 fodieatsFooter mt-4 d-inline-block">Powered by FodiEats</a>
        </Fragment>
    )
}

export default MenuTabCarousel;