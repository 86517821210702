import React, { Fragment } from 'react';
function InfoModal({ data = {} }) {
    return (
        <Fragment>
            {/* info_modal  */}
            <div className="modal fade" id="info_modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header justify-content-between border-bottom-0">
                            <span></span>
                            <h1 className="modal-title fs_18" id="info_modalLabel">Info</h1>
                            <button type="button" className="btn-close m-0" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div dangerouslySetInnerHTML={{ __html: data?.description || "" }} />
                        </div>
                    </div>
                </div>
            </div>
            {/* info_modal  */}
        </Fragment>
    );
};

export default InfoModal;