// get All Menu reducer
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../AXIOS";

// Create an async thunk all menu
export const getAllMenu = createAsyncThunk(
    'menu/all',
    async (params, thunkAPI) => {
        let id = parseInt(params.id);
        let lang = params.lang;
        const response = await API.post('/Api/getMenus', { id, language_id: lang });
        return response.data;
    }
);

export const menuSlice = createSlice({
    name: 'MENU',
    initialState: {
        loader: false,
        error: null,
        menuss: null
    },
    reducers: {
        // other reducer actions
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllMenu.pending, (state) => {
                state.loader = true;
            })
            .addCase(getAllMenu.fulfilled, (state, action) => {
                state.loader = false;
                state.menuss = action.payload;
            })
            .addCase(getAllMenu.rejected, (state, action) => {
                state.loader = false;
                state.error = action.error.message;
            })
    }
});

export default menuSlice.reducer;