// get All Location reducer
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../AXIOS";
import httpRequest from "../../AXIOS/httpRequest";

// Create an async thunk all location
export const getAllLocation = createAsyncThunk(
    'Location/All',
    async (_, { rejectWithValue }) => {
        const { res, err } = await httpRequest({ url: "/Api" });
        if (res) {
            return res;
        } else {
            return rejectWithValue(err);
        }
    }
);

// Create an async thunk unique location
export const getUniqueLocation = createAsyncThunk(
    'Location/Single',
    async (locId) => {
        const response = await API.post('/Api/getLocation', { id: locId });
        return response.data;
    }
);

export const LocationSlice = createSlice({
    name: 'LOCATION',
    initialState: {
        loader: false,
        error: null,
        allLocation: [],
        filterLocation: null,
    },
    reducers: {
        // other reducer actions
    },
    extraReducers: (builder) => {
        // get all location
        builder
            .addCase(getAllLocation.pending, (state) => {
                state.loader = true;
            })
            .addCase(getAllLocation.fulfilled, (state, { payload }) => {
                state.loader = false;
                state.allLocation = payload;
            })
            .addCase(getAllLocation.rejected, (state, { payload }) => {
                state.loader = false;
                state.error = payload;
            })
            // get unique location data
            .addCase(getUniqueLocation.pending, (state) => {
                state.loader = true;
            })
            .addCase(getUniqueLocation.fulfilled, (state, action) => {
                state.loader = false;
                state.filterLocation = action.payload;
            })
            .addCase(getUniqueLocation.rejected, (state, action) => {
                state.loader = false;
                state.error = action.error.message;
            });
    },
});

export default LocationSlice.reducer;