import axios from 'axios';
import { baseUrl } from '../Helps/helper';

const httpRequest = async ({ method = "get", url = "", data = {} }) => {
    try {
        let config = {
            headers: {
                'Content-Type': 'application/json',
            }
        };

        let response;
        if (["post", "put", "patch"].includes(method)) {
            response = await axios[method](`${baseUrl}${url}`, data);
        } else {
            response = await axios[method](`${baseUrl}${url}`, config);
        }

        return { res: response?.data };
    } catch (error) {
        return { err: error?.response?.data?.message || 'Something went wrong' };
    }
};

export default httpRequest;