import React, { Fragment, useEffect, useState } from 'react';
import DetailBanner from '../../Components/DetailBanner';
import DetailContentMain from '../../Components/DetailContentMain';
import { useParams } from 'react-router-dom';
import Loader from '../../Components/Loader';
import httpRequest from '../../Config/AXIOS/httpRequest';
import { toast } from 'react-toastify';

function MenuDetail() {
    const { location, company, productId } = useParams();

    const [isLoader, setIsLoader] = useState(false);
    const [ingredients, setIngredients] = useState([]);
    const [itemdetail, setItemdetail] = useState({});
    const [price, setPrice] = useState([]);
    const [specials, setSpecials] = useState([]);

    // // Function to fetch location data from the API
    const fecthDetails = async () => {
        let data = new FormData(); // Create a new FormData object
        data.append('id', productId); // append product id
        data.append('language_id', "en"); // Append language_id to the FormData object

        setIsLoader(true);
        const { res, err } = await httpRequest({ method: "post", url: "/Api/getItemDetail", data }); // Make a POST request to fetch menus data
        if (res) {
            let { ingredients = [], itemdetail = {}, price = [], specials = [] } = res;
            setIngredients(ingredients);
            setItemdetail(itemdetail);
            setPrice(price);
            setSpecials(specials);
        } else {
            toast(err || "Error", { type: "error" }); // Display an error message using toast
        }
        setIsLoader(false);
    };

    // useEffect hook to fetch item details when the component mounts or the location, company & productId changes
    useEffect(() => {
        fecthDetails(); // Fetch location data
    }, [location, company, productId]);
    return (
        <Fragment>
            <section className="detail" id="detail">
                {isLoader ?
                    <Loader />
                    :
                    <>
                        <DetailBanner
                            itemdetail={itemdetail}
                        />
                        <DetailContentMain
                            itemdetail={itemdetail}
                            ingredients={ingredients}
                            specials={specials}
                            price={price}
                        />
                    </>
                }
            </section>
        </Fragment>
    );
};

export default MenuDetail;