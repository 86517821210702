import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
// ==============REDUX PACKAGES==============
import { useDispatch } from 'react-redux';
// ==============Components==============
import { ScrollToTop } from '../../Components/ScrollToTop';
import RightMenu from '../../Components/RightMenu';
import { domainName, getCompanyAndLocation } from '../Helps/helper';
// ==============PAGES==============
import Home from '../../Screens/Home';
import Menu from '../../Screens/Menu';
import Detail from '../../Screens/MenuDatail';
import NotFound from '../../Screens/NotFound'; // not fo;
import httpRequest from '../AXIOS/httpRequest';
import { toast } from 'react-toastify';
import { setLocationData, toogleHomeLoader } from '../Store/Reducer/fodiEats';
function AppRouter() {
    const _location = useLocation(); // Retrieve the current location object from React Router

    const dispatch = useDispatch(); // Initialize the dispatch function from Redux

    // Function to fetch location data from the API
    const fecthLocationsData = async ({ location, company }) => {
        let data = new FormData(); // Create a new FormData object
        data.append('company', company); // Append company to the FormData object
        data.append('location', location); // Append location to the FormData object
        data.append('language_id', "en"); // Append language_id to the FormData object

        dispatch(toogleHomeLoader(true));
        const { res, err } = await httpRequest({ method: "post", url: "/Api/getLocation", data }); // Make a POST request to fetch location data
        if (res) {
            dispatch(setLocationData(res)); // Dispatch the response data to the Redux store
        } else {
            toast(err || "Error", { type: "error" }); // Display an error message using toast
        }
        dispatch(toogleHomeLoader(false));
    };

    // useEffect hook to fetch location data when the component mounts or the location changes
    useEffect(() => {
        const { company, location } = getCompanyAndLocation(_location?.pathname); // Extract company and location from the pathname
        if (!company || !location) {
            window.location.href = domainName; // Redirect to the domain name if company or location is missing
        } else {
            fecthLocationsData({ location, company }); // Fetch location data
        }
    }, []);

    return (
        <>
            <ScrollToTop />
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12 col-sm-10 col-md-6 m-auto col-lg-4 leftMenu position-relative p-0'>
                        <Routes>
                            <Route path={"/:company/:location"} element={<Home />} />
                            <Route path={"/:company/:location/menu"} element={<Menu />} />
                            <Route path={"/:company/:location/menu/:productId/view"} element={<Detail />} />
                            <Route path='*' element={<NotFound />} />
                        </Routes>
                    </div>
                    <div className='col-12 col-lg-8 flexCenter h-100 rightMenu'>
                        <RightMenu />
                    </div>
                </div>
            </div>
        </>
    );
};

export default AppRouter;