import React, { Fragment, useState } from 'react';
import SegmentIcon from '@mui/icons-material/Segment';
import CallRestaurant from '../CallRestaurant';
import { useSelector } from 'react-redux';

function MenuDrawer() {
    const [isLangDrawerOpen, setIsLangDrawerOpen] = useState(false);
    const lang = localStorage.getItem('FodiEats') || "en"; // localStorage getLang

    const changeLanguage = code => {
        localStorage.setItem('FodiEats', code);
        window.location.reload();
    }

    const { language } = useSelector(store => store.fodiEats);

    return (
        <Fragment>
            {/* menu side bar */}
            <div className="offcanvas offcanvas-end position-absolute w-100" style={{ maxHeight: "100svh" }} data-bs-scroll="false" tabIndex="-1" id="menuDrawer"
                aria-labelledby="menuDrawerLabel" data-bs-backdrop="false">
                <div className="offcanvas-header">
                    <span />
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={() => setIsLangDrawerOpen(false)}
                    />
                </div>
                <div className="offcanvas-body position-relative overflow-hidden">
                    {language?.length > 1 ?
                        <>
                            <button onClick={() => setIsLangDrawerOpen(true)} className="allUnset border-bottom d-block w-100 text-center py-3 fs_24 fw-500 text-dark">
                                Language
                            </button>
                            <div className={`position-absolute langDrawer ${isLangDrawerOpen ? 'open' : ''}`}>
                                {language?.filter(v => v?.name !== lang)?.map((item, index) => (
                                    <button key={index} onClick={() => changeLanguage(item?.name)} className="allUnset border-bottom d-block w-100 text-center py-3 fs_24 fw-500 text-dark">
                                        {item?.text}
                                    </button>
                                ))}
                            </div>
                        </>
                        : null
                    }
                    {/* open info modal */}
                    <CallRestaurant />
                </div>
            </div>
            {/* menu side bar */}
            <button className="backNavigation rounded shadow flexCenter" type="button" data-bs-toggle="offcanvas" data-bs-target="#menuDrawer"
                aria-controls="menuDrawer">
                <SegmentIcon />
            </button>
        </Fragment>
    )
}

export default MenuDrawer;