import React, { useEffect, useState } from 'react';
import Loader from '../../Components/Loader';
import { Link, useNavigate } from 'react-router-dom';
import InfoModal from '../../Components/InfoModal';
import LanguageModal from '../../Components/LanguageModal';
import SocialIcon from '../../Components/SocialIcon';
import { useSelector } from 'react-redux';
import LogoSkeleton from '../../Components/LogoSkeleton';
import Icon from 'react-icons-kit';
import { chevronRight } from 'react-icons-kit/oct';
import { ic_info_outline } from 'react-icons-kit/md';
import { baseImageUrl } from '../../Config/Helps/helper';
function Home() {
    const navigate = useNavigate();
    const [imgError, setImgError] = useState(false);
    const { location = {}, language = [], location_info, location_social_media, homeLoader } = useSelector(state => state.fodiEats);

    useEffect(() => {
        if (location) {
            if (location?.skip_home === '1') {
                navigate('./menu');
            };
        };
    }, [location]);

    return (
        <section className="home" id="home"> {/*  conditional change ltr,rtl */}
            {homeLoader && <Loader />}
            <div className="border-bottom">
                <div className="header py-5 text-center">
                    {
                        imgError ?
                            <LogoSkeleton h={130} w={130} />
                            :
                            <img
                                src={`${baseImageUrl}/${location?.image}`}
                                onError={() => setImgError(true)}
                                alt="logo" className="logo mb-3"
                            />
                    }
                    <p className="fs_18">{location?.tag1 || ""}</p>
                    {/* language multiple then show otherwise hide */}
                    {language?.length &&
                        <LanguageModal data={language} />
                    }
                    <Link to={`./menu`} className="btnPrimary cursor-pointer">Menu</Link>
                </div>
            </div>
            <div className="listing">
                {location_info &&
                    <>
                        <a href="#" data-bs-toggle="modal" data-bs-target="#info_modal"
                            className="border-bottom d-block text-dark">
                            <div className="px-2 py-3 d-flex justify-content-between align-items-center">
                                <span className="fs_18"><Icon icon={ic_info_outline} size={20} className='icon' /> Info</span>
                                <Icon className='rotate180' icon={chevronRight} size={20} />
                            </div>
                        </a>
                        <InfoModal
                            data={location_info}
                        />
                    </>
                }
            </div>
            {
                location_social_media?.length &&
                <SocialIcon data={location_social_media} />
            }
        </section>
    );
};

export default Home;