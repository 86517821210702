import React from 'react';
import './detailbanner.css';
import { useNavigate } from 'react-router-dom';
import Icon from 'react-icons-kit';
import { longArrowLeft, clockO } from 'react-icons-kit/fa';

function DetailBanner({ itemdetail }) {
    const navigate = useNavigate();
    return (
        <div className="banner">
            <button onClick={() => navigate(-1)} className="backNavigate flexCenter rotate180 border-0">
                <Icon icon={longArrowLeft} />
            </button>
            <div className="d-flex justify-content-between bottomDetail">
                {itemdetail?.preparation_time !== "00:00:00" &&
                <div className="flexCenter rounded-pill time">
                    <Icon icon={clockO} />
                    <span><span>{itemdetail?.preparation_time || ""}</span> Min</span>
                </div>
                }
            </div>
            <img src={itemdetail?.image} alt="banner" className="img-fluid w-100 detailedBanner" />
        </div>
    );
};

export default DetailBanner;