import React from 'react';
import Icon from 'react-icons-kit';
// sweet alert
import Swal from 'sweetalert2'
import { instagram, linkedin, facebookF, pinterest, link, youtube, twitter } from 'react-icons-kit/fa';
import './socialIcon.css';

function SocialIcon({ data = [] }) {
    // copy url function
    const handleCopyClick = () => {
        let url = window.location.href;
        if (!url.endsWith('/')) {
            url += '/';
        }
        url += 'menu';

        // Use the Clipboard API to copy the URL to the clipboard
        navigator.clipboard.writeText(url)
            .then(() => {
                Swal.fire(
                    'URL copied to clipboard:',
                    url,
                    'success'
                );
            })
            .catch((error) => {
                Swal.fire(
                    'URL does not copied to clipboard:',
                    error,
                    'error'
                );
            });
    };
    // set icon by condition
    const renderIcon = (name) => {
        switch (name) {
            case 'facebook':
                return facebookF;
            case 'linkedin':
                return linkedin;
            case 'instagram':
                return instagram;
            case 'pinterest':
                return pinterest;
            case 'youtube':
                return youtube;
            case 'twitter':
                return twitter;
            default:
                return link;
        }
    };
    return (
        <div className='footer py-4'>
            <div className='socialIconContainer d-flex justify-content-center flex-wrap gap-15'>
                {data?.map((item, index) => (
                    item.url ? (
                        <a key={index} className='icon' target='_blank' rel="noreferrer" href={item.url}>
                            <Icon icon={renderIcon(item?.name)} size={20} />
                        </a>
                    ) : null
                ))}
                <button onClick={handleCopyClick} className='icon'>
                    <Icon icon={link} size={20} />
                </button>
            </div>
        </div>
    );
};

export default SocialIcon;