import axios from 'axios';

const API = axios.create();

// Add an Axios interceptor for requests
API.interceptors.request.use((config) => {
    // Set the base URL for requests
    config.baseURL = 'https://fodieats.com/backend/index.php'; // Set your desired baseURL here
    return config;
});

export default API;
