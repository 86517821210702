import React from 'react';
import { useNavigate } from 'react-router-dom';
import Icon from 'react-icons-kit';
import { angleLeft } from 'react-icons-kit/fa';

function BackNavigation({ extra }) {
    const navigate = useNavigate();
    return (
        <div className={`flex_1_1_0 ${extra}`}>
            <button onClick={() => navigate(-1)} className="backNavigation rounded shadow flexCenter">
                <Icon className='rotate180' icon={angleLeft} size={22} />
            </button>
        </div>
    )
}

export default BackNavigation;