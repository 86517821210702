import React, { Fragment, useEffect, useState } from 'react';
import { Icon } from 'react-icons-kit';
import { ic_keyboard_arrow_down_twotone } from 'react-icons-kit/md';
import { globe } from 'react-icons-kit/feather';
function LanguageModal({ data = [] }) {
    const [lang, setLang] = useState(localStorage.getItem('FodiEats') || "en"); // localStorage getLang
    useEffect(() => {
        // if lang not set in localstorage then api bydefaultset local storage
        if (!lang) {
            let APIDEFAULTLANG = data.find(item => item?.default_language == 1).name; // API default lang
            localStorage.setItem('FodiEats', APIDEFAULTLANG);
            setLang(APIDEFAULTLANG);
        }
    }, [data, lang]);

    const changeLanguage = code => {
        localStorage.setItem('FodiEats', code);
        window.location.reload();
    };
    return (
        <Fragment>
            <button className="langaueModalBtn mb-3" data-bs-toggle="modal"
                data-bs-target="#language_modal">
                <Icon icon={globe} size={18} />
                <span className="text-decoration-underline mx-1">{data.find(item => item.name == lang)?.text}</span>
                <Icon icon={ic_keyboard_arrow_down_twotone} size={24} />
            </button>
            {/* language_modal  */}
            <div className="modal fade" id="language_modal" tabIndex="-1" aria-labelledby="language_modalLabel"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header justify-content-between border-bottom-0">
                            <span></span>
                            <h1 className="modal-title fs_18" id="languageModalLabel">Select Language</h1>
                            <button type="button" className="btn-close m-0" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {
                                data.filter(item => item.name !== lang).map((v, i) => (
                                    <button key={i} onClick={() => changeLanguage(v?.name)} type="button" className="languageList text-start">{v?.text}</button>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            {/* language_modal  */}
        </Fragment>
    );
};

export default LanguageModal;