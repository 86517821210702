import React, { useState } from 'react';
import './rightMenu.css';
import { useSelector } from 'react-redux';
import LogoSkeleton from '../LogoSkeleton';
import { baseImageUrl } from '../../Config/Helps/helper';
function RightMenu() {
    const { location = {}, homeLoader } = useSelector(state => state.fodiEats);
    const [imageError, setImageError] = useState(false); // for image is not exist

    return (
        <div className="text-center main">
            {
                imageError || homeLoader
                    ?
                    <LogoSkeleton h={130} w={130} />
                    :
                    <img
                        src={`${baseImageUrl}${location?.image}`}
                        alt="logo"
                        className="logo mb-3"
                        onError={() => setImageError(true)}
                    />
            }
            <div className="primaryDivider mb-3"></div>
            {
                homeLoader
                    ?
                    <LogoSkeleton h={30} w={200} />
                    :
                    <h3 className="text-capitalize">{location?.location_name || ""}</h3>
            }
        </div>
    )
}

export default RightMenu;