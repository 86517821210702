import React, { Fragment } from 'react';
import './callRestaurant.css';

function CallRestaurant() {
    return (
        <Fragment>
            <a href="#" data-bs-toggle="modal" data-bs-target="#info_modal" className="callRestaurant rounded-pill"><i
                className="fa-solid fa-phone me-2"></i> Call Restaurant</a>
        </Fragment>
    )
}

export default CallRestaurant;